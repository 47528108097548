import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getPageableAsParams, Page, Pageable } from '../../models/page.model';
import {ICounterparty, ICounterpartySearchCriteria} from "../../models/fx/counterparty.model";

@Injectable({
  providedIn: 'root'
})
export class CounterpartyService {
  private apiUrl = 'forex/v1/counterparties';

  constructor(private http: HttpClient) {}

  createCounterparty(counterparty: ICounterparty): Observable<ICounterparty> {
    return this.http.post<ICounterparty>(this.apiUrl, counterparty);
  }

  updateCounterparty(id: string, counterparty: ICounterparty): Observable<ICounterparty> {
    return this.http.put<ICounterparty>(`${this.apiUrl}/${id}`, counterparty);
  }

  deleteCounterparty(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }

  getCounterparty(id: string): Observable<ICounterparty> {
    return this.http.get<ICounterparty>(`${this.apiUrl}/${id}`);
  }

  getAllCounterparties(pageable: Pageable): Observable<Page<ICounterparty>> {
    const params = getPageableAsParams(pageable);
    return this.http.get<Page<ICounterparty>>(this.apiUrl, { params });
  }

  searchCounterparties(criteria: ICounterpartySearchCriteria, pageable: Pageable): Observable<Page<ICounterparty>> {
    let params = getPageableAsParams(pageable);
    if (criteria.name) params = params.append('name', criteria.name);
    if (criteria.type) params = params.append('type', criteria.type);
    if (criteria.bicCode) params = params.append('bicCode', criteria.bicCode);
    if (criteria.swiftCode) params = params.append('swiftCode', criteria.swiftCode);

    console.log('Params: ', params)
    return this.http.get<Page<ICounterparty>>(`${this.apiUrl}/search`, { params });
  }
}
